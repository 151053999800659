import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    classroomCreateClassroom: build.mutation<
      ClassroomCreateClassroomApiResponse,
      ClassroomCreateClassroomApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/classroom`,
        method: 'POST',
        body: queryArg.createClassroomRequest,
      }),
    }),
    classroomListClassrooms: build.query<
      ClassroomListClassroomsApiResponse,
      ClassroomListClassroomsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/classrooms`,
        params: { user_id: queryArg.userId },
      }),
    }),
    classroomGetClassroom: build.query<
      ClassroomGetClassroomApiResponse,
      ClassroomGetClassroomApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/sdk/classroom/classrooms/${queryArg.id}` }),
    }),
    classroomJoinClassroom: build.mutation<
      ClassroomJoinClassroomApiResponse,
      ClassroomJoinClassroomApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/classrooms/${queryArg.code}/join`,
        method: 'POST',
      }),
    }),
    classroomUnjoinClassroom: build.mutation<
      ClassroomUnjoinClassroomApiResponse,
      ClassroomUnjoinClassroomApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/classrooms/${queryArg.code}/unjoin`,
        method: 'POST',
      }),
    }),
    classroomCreateClassroomTask: build.mutation<
      ClassroomCreateClassroomTaskApiResponse,
      ClassroomCreateClassroomTaskApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/task`,
        method: 'POST',
        body: queryArg.createClassroomTaskRequest,
      }),
    }),
    classroomListClassroomTasks: build.query<
      ClassroomListClassroomTasksApiResponse,
      ClassroomListClassroomTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/tasks/`,
        params: {
          classroom_id: queryArg.classroomId,
          is_active: queryArg.isActive,
          is_upcoming: queryArg.isUpcoming,
          for_you: queryArg.forYou,
        },
      }),
    }),
    classroomGetClassroomTask: build.query<
      ClassroomGetClassroomTaskApiResponse,
      ClassroomGetClassroomTaskApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/sdk/classroom/tasks/${queryArg.id}` }),
    }),
    classroomReplyClassroomTask: build.mutation<
      ClassroomReplyClassroomTaskApiResponse,
      ClassroomReplyClassroomTaskApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/tasks/${queryArg.id}/reply`,
        method: 'POST',
        body: queryArg.replyClassroomTaskRequest,
      }),
    }),
    classroomGetJoinedStudentsTaskReplies: build.query<
      ClassroomGetJoinedStudentsTaskRepliesApiResponse,
      ClassroomGetJoinedStudentsTaskRepliesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/classroom/${queryArg.id}/joined_student_task_replies`,
      }),
    }),
    classroomClassroomInviteStudent: build.mutation<
      ClassroomClassroomInviteStudentApiResponse,
      ClassroomClassroomInviteStudentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/classroom/${queryArg.id}/invite_student`,
        method: 'POST',
        body: queryArg.classroomInviteStudentRequest,
      }),
    }),
    classroomGetCurrentClassroom: build.query<
      ClassroomGetCurrentClassroomApiResponse,
      ClassroomGetCurrentClassroomApiArg
    >({
      query: () => ({ url: `/api/v1/sdk/classroom/classroom/current_classroom` }),
    }),
    classroomDeleteClassroomTask: build.mutation<
      ClassroomDeleteClassroomTaskApiResponse,
      ClassroomDeleteClassroomTaskApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/task/${queryArg.taskId}`,
        method: 'DELETE',
      }),
    }),
    classroomUpdateClassroomTask: build.mutation<
      ClassroomUpdateClassroomTaskApiResponse,
      ClassroomUpdateClassroomTaskApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/task/${queryArg.taskId}`,
        method: 'PUT',
        body: queryArg.classroomTaskUpdateRequest,
      }),
    }),
    classroomListClassroomStudents: build.query<
      ClassroomListClassroomStudentsApiResponse,
      ClassroomListClassroomStudentsApiArg
    >({
      query: () => ({ url: `/api/v1/sdk/classroom/classroom/students` }),
    }),
    classroomRemoveStudentFromClassroom: build.mutation<
      ClassroomRemoveStudentFromClassroomApiResponse,
      ClassroomRemoveStudentFromClassroomApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/classroom/remove_student`,
        method: 'POST',
        body: queryArg.classroomRemoveStudentRequest,
      }),
    }),
    classroomUpdateClassroomMaxSubscribers: build.mutation<
      ClassroomUpdateClassroomMaxSubscribersApiResponse,
      ClassroomUpdateClassroomMaxSubscribersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/classroom/update`,
        method: 'POST',
        body: queryArg.classroomUpdateMaxSubscribersRequest,
      }),
    }),
    classroomGetIspeakTasks: build.query<
      ClassroomGetIspeakTasksApiResponse,
      ClassroomGetIspeakTasksApiArg
    >({
      query: () => ({ url: `/api/v1/sdk/classroom/classroom/ispeak_tasks` }),
    }),
    classroomAddIspeakTaskToClassroomTasks: build.mutation<
      ClassroomAddIspeakTaskToClassroomTasksApiResponse,
      ClassroomAddIspeakTaskToClassroomTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/classroom/add_ispeak_task_to_classroom_tasks`,
        method: 'POST',
        body: queryArg.addIspeakTaskToClassroomTasksRequest,
      }),
    }),
    classroomAddTasksFolder: build.mutation<
      ClassroomAddTasksFolderApiResponse,
      ClassroomAddTasksFolderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/classroom/create_task_folder`,
        method: 'POST',
        body: queryArg.addTasksFolderRequest,
      }),
    }),
    classroomGetTeacherTaskFolders: build.query<
      ClassroomGetTeacherTaskFoldersApiResponse,
      ClassroomGetTeacherTaskFoldersApiArg
    >({
      query: () => ({ url: `/api/v1/sdk/classroom/classroom/get_teacher_task_folders` }),
    }),
    classroomGetLivePracticeTask: build.query<
      ClassroomGetLivePracticeTaskApiResponse,
      ClassroomGetLivePracticeTaskApiArg
    >({
      query: () => ({ url: `/api/v1/sdk/classroom/classroom/get_live_practice_task` }),
    }),
    classroomOrderClassroomTasks: build.mutation<
      ClassroomOrderClassroomTasksApiResponse,
      ClassroomOrderClassroomTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/tasks/order`,
        method: 'POST',
        body: queryArg.classroomTasksOrderRequest,
      }),
    }),
    classroomDeleteClassroomTasksFolder: build.mutation<
      ClassroomDeleteClassroomTasksFolderApiResponse,
      ClassroomDeleteClassroomTasksFolderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/classroom/${queryArg.folderId}`,
        method: 'DELETE',
      }),
    }),
    classroomUpdateClassroomTasksFolder: build.mutation<
      ClassroomUpdateClassroomTasksFolderApiResponse,
      ClassroomUpdateClassroomTasksFolderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/classroom/classroom/update_folder`,
        method: 'POST',
        body: queryArg.classroomUpdateTasksFolderRequest,
      }),
    }),
    classroomGetIeltsTasks: build.query<
      ClassroomGetIeltsTasksApiResponse,
      ClassroomGetIeltsTasksApiArg
    >({
      query: () => ({ url: `/api/v1/sdk/classroom/classroom/ielts_tasks` }),
    }),
    classroomGetToeflTasks: build.query<
      ClassroomGetToeflTasksApiResponse,
      ClassroomGetToeflTasksApiArg
    >({
      query: () => ({ url: `/api/v1/sdk/classroom/classroom/toelf_tasks` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type ClassroomCreateClassroomApiResponse =
  /** status 201 Successful Response */ CreateClassroomResponse
export type ClassroomCreateClassroomApiArg = {
  createClassroomRequest: CreateClassroomRequest
}
export type ClassroomListClassroomsApiResponse =
  /** status 200 Successful Response */ ClassroomResponse[]
export type ClassroomListClassroomsApiArg = {
  userId?: number | null
}
export type ClassroomGetClassroomApiResponse =
  /** status 200 Successful Response */ ClassroomResponse
export type ClassroomGetClassroomApiArg = {
  id: number
}
export type ClassroomJoinClassroomApiResponse =
  /** status 201 Successful Response */ JoinClassroomResponse
export type ClassroomJoinClassroomApiArg = {
  code: string
}
export type ClassroomUnjoinClassroomApiResponse = /** status 200 Successful Response */ any
export type ClassroomUnjoinClassroomApiArg = {
  code: string
}
export type ClassroomCreateClassroomTaskApiResponse =
  /** status 201 Successful Response */ ClassroomTaskResponse
export type ClassroomCreateClassroomTaskApiArg = {
  createClassroomTaskRequest: CreateClassroomTaskRequest
}
export type ClassroomListClassroomTasksApiResponse =
  /** status 200 Successful Response */ ClassroomTaskResponse[]
export type ClassroomListClassroomTasksApiArg = {
  classroomId?: number | null
  isActive?: boolean | null
  isUpcoming?: boolean | null
  forYou?: boolean | null
}
export type ClassroomGetClassroomTaskApiResponse =
  /** status 200 Successful Response */ ClassroomTaskResponse
export type ClassroomGetClassroomTaskApiArg = {
  id: number
}
export type ClassroomReplyClassroomTaskApiResponse =
  /** status 200 Successful Response */ ClassroomTaskReplyResponse
export type ClassroomReplyClassroomTaskApiArg = {
  id: number
  replyClassroomTaskRequest: ReplyClassroomTaskRequest
}
export type ClassroomGetJoinedStudentsTaskRepliesApiResponse =
  /** status 200 Successful Response */ object[]
export type ClassroomGetJoinedStudentsTaskRepliesApiArg = {
  id: number
}
export type ClassroomClassroomInviteStudentApiResponse = /** status 200 Successful Response */ any
export type ClassroomClassroomInviteStudentApiArg = {
  id: number
  classroomInviteStudentRequest: ClassroomInviteStudentRequest
}
export type ClassroomGetCurrentClassroomApiResponse =
  /** status 200 Successful Response */ ClassroomResponse | null
export type ClassroomGetCurrentClassroomApiArg = void
export type ClassroomDeleteClassroomTaskApiResponse =
  /** status 200 Successful Response */ ClassroomDeleteTaskResponse
export type ClassroomDeleteClassroomTaskApiArg = {
  taskId: number
}
export type ClassroomUpdateClassroomTaskApiResponse = /** status 201 Successful Response */ any
export type ClassroomUpdateClassroomTaskApiArg = {
  taskId: number
  classroomTaskUpdateRequest: ClassroomTaskUpdateRequest
}
export type ClassroomListClassroomStudentsApiResponse = /** status 200 Successful Response */ any
export type ClassroomListClassroomStudentsApiArg = void
export type ClassroomRemoveStudentFromClassroomApiResponse =
  /** status 201 Successful Response */ any
export type ClassroomRemoveStudentFromClassroomApiArg = {
  classroomRemoveStudentRequest: ClassroomRemoveStudentRequest
}
export type ClassroomUpdateClassroomMaxSubscribersApiResponse =
  /** status 201 Successful Response */ any
export type ClassroomUpdateClassroomMaxSubscribersApiArg = {
  classroomUpdateMaxSubscribersRequest: ClassroomUpdateMaxSubscribersRequest
}
export type ClassroomGetIspeakTasksApiResponse = /** status 200 Successful Response */ any
export type ClassroomGetIspeakTasksApiArg = void
export type ClassroomAddIspeakTaskToClassroomTasksApiResponse =
  /** status 201 Successful Response */ any
export type ClassroomAddIspeakTaskToClassroomTasksApiArg = {
  addIspeakTaskToClassroomTasksRequest: AddIspeakTaskToClassroomTasksRequest
}
export type ClassroomAddTasksFolderApiResponse = /** status 201 Successful Response */ any
export type ClassroomAddTasksFolderApiArg = {
  addTasksFolderRequest: AddTasksFolderRequest
}
export type ClassroomGetTeacherTaskFoldersApiResponse = /** status 200 Successful Response */ any
export type ClassroomGetTeacherTaskFoldersApiArg = void
export type ClassroomGetLivePracticeTaskApiResponse = /** status 200 Successful Response */ any
export type ClassroomGetLivePracticeTaskApiArg = void
export type ClassroomOrderClassroomTasksApiResponse = /** status 200 Successful Response */ any
export type ClassroomOrderClassroomTasksApiArg = {
  classroomTasksOrderRequest: ClassroomTasksOrderRequest
}
export type ClassroomDeleteClassroomTasksFolderApiResponse =
  /** status 200 Successful Response */ ClassroomDeleteTasksFolderResponse
export type ClassroomDeleteClassroomTasksFolderApiArg = {
  folderId: number
}
export type ClassroomUpdateClassroomTasksFolderApiResponse =
  /** status 201 Successful Response */ any
export type ClassroomUpdateClassroomTasksFolderApiArg = {
  classroomUpdateTasksFolderRequest: ClassroomUpdateTasksFolderRequest
}
export type ClassroomGetIeltsTasksApiResponse = /** status 200 Successful Response */ any
export type ClassroomGetIeltsTasksApiArg = void
export type ClassroomGetToeflTasksApiResponse = /** status 200 Successful Response */ any
export type ClassroomGetToeflTasksApiArg = void
export type CreateClassroomResponse = {
  id: number
  name: string
  description: string
  image?: string | null
  code: string
  created_at: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type CreateClassroomRequest = {
  name: string
  description: string
}
export type ClassRoomUser = {
  username: string
  full_name?: string | null
  image?: string | null
  avatar?: string | null
}
export type ClassroomResponse = {
  id: number
  name: string
  description: string
  image?: string | null
  code: string
  created_at: string
  user?: ClassRoomUser | null
  max_subscribers: number
}
export type JoinClassroomResponse = {
  id: number
  user_id: number
  classroom_id: number
}
export type TaskQuestionResponse = {
  id: number
  text: string
  created_at: string
}
export type GameType =
  | 'NONE'
  | 'GEO_GAME'
  | 'CAPTION_THIS'
  | 'HYPERBEAM'
  | 'PICTIONARY'
  | 'JUST_CHAT'
  | 'JKLM_FUN'
  | 'DISCUSSION'
  | 'WHITEBOARD'
  | 'CHAT'
  | 'GROUP_LESSON'
export type Pronouns = 0 | 1 | 2 | 3
export type TeacherProfileResponse = {
  bio?: string | null
  experience?: string | null
  tags?: string[] | null
  video_link?: string | null
  teaching_style?: string | null
  price_per_hour?: number | null
  partner?: boolean | null
  is_reviewer?: boolean | null
}
export type LanguageExperience = 0 | 1 | 2 | 3 | 4
export type LanguageProficiency = {
  language: string
  proficiency: LanguageExperience
}
export type AddressInfoInDb = {
  country?: string | null
  city?: string | null
  state?: string | null
  address?: string | null
  zip_code?: string | null
}
export type UserStatus = 'STUDENT' | 'TEACHER'
export type PublicUserProfile = {
  id?: number | null
  username?: string | null
  email?: string | null
  bio?: string | null
  image?: string | null
  full_name?: string | null
  country?: string | null
  pronouns?: Pronouns | null
  date_of_birth?: string | null
  profile?: TeacherProfileResponse | null
  completed_onboarding?: boolean | null
  verified?: boolean | null
  accent?: string | null
  voice_gender?: string | null
  learning?: LanguageProficiency[] | null
  address?: AddressInfoInDb | null
  speaking?: LanguageProficiency[] | null
  status?: UserStatus
  display_name?: string | null
  is_company?: boolean | null
  cefr_level?: string | null
}
export type GameStatus = 'PENDING' | 'STARTED' | 'FINISHED' | 'CANCELED'
export type EventResponse = {
  id: number
  event_id: string
  event_type?: GameType | null
  current_room_id?: string | null
  event_language: string
  event_title?: string | null
  event_description?: string | null
  start_time_utc: string
  end_time_utc?: string | null
  host: PublicUserProfile
  proficiency: LanguageExperience
  participants: PublicUserProfile[]
  users?: PublicUserProfile[] | null
  is_live?: boolean
  short_event_id?: string | null
  status?: GameStatus | null
  is_private?: boolean | null
  tags?: string[] | null
  game_status?: GameStatus | null
  color_attribute?: string | null
  users_partition?: object
}
export type ClassroomTaskResponse = {
  id: number
  title: string
  description?: string | null
  classroom: ClassroomResponse
  start_time: string
  end_time: string
  video_link?: string | null
  thumbnail_link?: string | null
  questions: TaskQuestionResponse[]
  created_at: string
  color?: string | null
  external_link?: string | null
  event?: EventResponse | null
}
export type CreateClassroomTaskRequest = {
  classroom_id: number
  title: string
  description?: string | null
  video_link?: string | null
  thumbnail_link?: string | null
  start_time: string
  end_time: string
  questions: string[]
  show_tasks_when_record?: boolean
  show_profile_picture?: boolean
  tags: string[]
  color?: string | null
  folders?: number[] | null
  external_link?: string | null
}
export type ClassroomTaskReplyResponse = {
  id: number
  text_reply?: string | null
  audio_reply_url?: string | null
  task_id: number
  user_id: number
  created_at: string
}
export type ReplyClassroomTaskRequest = {
  text_reply?: string | null
  audio_reply?: Blob | null
}
export type ClassroomInviteStudentRequest = {
  email: string
}
export type ClassroomDeleteTaskResponse = {
  task_id: number
  classroom_id: number
}
export type ClassroomTaskUpdateRequest = {
  questions?: string[] | null
  color?: string | null
  video_link?: string | null
  external_link?: string | null
}
export type ClassroomRemoveStudentRequest = {
  user_id: number
  classroom_id: number
}
export type ClassroomUpdateMaxSubscribersRequest = {
  max_subscribers: number
}
export type AddIspeakTaskToClassroomTasksRequest = {
  task_id: number
}
export type AddTasksFolderRequest = {
  name: string
  visibility: number[]
}
export type TaskOrderingReq = {
  task_id: number
  order: number
}
export type ClassroomTasksOrderRequest = {
  task_ordering: TaskOrderingReq[]
}
export type ClassroomDeleteTasksFolderResponse = {
  id: number
}
export type ClassroomUpdateTasksFolderRequest = {
  id: number
  name?: string | null
  visibility?: number[] | null
}
export const {
  useClassroomCreateClassroomMutation,
  useClassroomListClassroomsQuery,
  useLazyClassroomListClassroomsQuery,
  useClassroomGetClassroomQuery,
  useLazyClassroomGetClassroomQuery,
  useClassroomJoinClassroomMutation,
  useClassroomUnjoinClassroomMutation,
  useClassroomCreateClassroomTaskMutation,
  useClassroomListClassroomTasksQuery,
  useLazyClassroomListClassroomTasksQuery,
  useClassroomGetClassroomTaskQuery,
  useLazyClassroomGetClassroomTaskQuery,
  useClassroomReplyClassroomTaskMutation,
  useClassroomGetJoinedStudentsTaskRepliesQuery,
  useLazyClassroomGetJoinedStudentsTaskRepliesQuery,
  useClassroomClassroomInviteStudentMutation,
  useClassroomGetCurrentClassroomQuery,
  useLazyClassroomGetCurrentClassroomQuery,
  useClassroomDeleteClassroomTaskMutation,
  useClassroomUpdateClassroomTaskMutation,
  useClassroomListClassroomStudentsQuery,
  useLazyClassroomListClassroomStudentsQuery,
  useClassroomRemoveStudentFromClassroomMutation,
  useClassroomUpdateClassroomMaxSubscribersMutation,
  useClassroomGetIspeakTasksQuery,
  useLazyClassroomGetIspeakTasksQuery,
  useClassroomAddIspeakTaskToClassroomTasksMutation,
  useClassroomAddTasksFolderMutation,
  useClassroomGetTeacherTaskFoldersQuery,
  useLazyClassroomGetTeacherTaskFoldersQuery,
  useClassroomGetLivePracticeTaskQuery,
  useLazyClassroomGetLivePracticeTaskQuery,
  useClassroomOrderClassroomTasksMutation,
  useClassroomDeleteClassroomTasksFolderMutation,
  useClassroomUpdateClassroomTasksFolderMutation,
  useClassroomGetIeltsTasksQuery,
  useLazyClassroomGetIeltsTasksQuery,
  useClassroomGetToeflTasksQuery,
  useLazyClassroomGetToeflTasksQuery,
} = injectedRtkApi
