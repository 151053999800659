import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ratingRateUser: build.mutation<RatingRateUserApiResponse, RatingRateUserApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/rating/rate`,
        method: 'POST',
        body: queryArg.ratingRequest,
      }),
    }),
    ratingDeleteRating: build.mutation<RatingDeleteRatingApiResponse, RatingDeleteRatingApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/rating/rate/${queryArg.ratingId}`,
        method: 'DELETE',
      }),
    }),
    ratingChangeRatingForUser: build.mutation<
      RatingChangeRatingForUserApiResponse,
      RatingChangeRatingForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/rating/rate/${queryArg.ratingId}`,
        method: 'PUT',
        body: queryArg.patchRatingRequest,
      }),
    }),
    ratingGetRatingId: build.query<RatingGetRatingIdApiResponse, RatingGetRatingIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/extensions/rating/rate/${queryArg.ratingId}` }),
    }),
    ratingGetRatingsForUser: build.query<
      RatingGetRatingsForUserApiResponse,
      RatingGetRatingsForUserApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/extensions/rating/rate/user/${queryArg.username}` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type RatingRateUserApiResponse = /** status 201 Successful Response */ CreateRatingResponse
export type RatingRateUserApiArg = {
  ratingRequest: RatingRequest
}
export type RatingDeleteRatingApiResponse = /** status 200 Successful Response */ any
export type RatingDeleteRatingApiArg = {
  ratingId: number
}
export type RatingChangeRatingForUserApiResponse = /** status 200 Successful Response */ any
export type RatingChangeRatingForUserApiArg = {
  ratingId: number
  patchRatingRequest: PatchRatingRequest
}
export type RatingGetRatingIdApiResponse = /** status 200 Successful Response */ UserRating
export type RatingGetRatingIdApiArg = {
  ratingId: number
}
export type RatingGetRatingsForUserApiResponse = /** status 200 Successful Response */ UserRating[]
export type RatingGetRatingsForUserApiArg = {
  username: string
}
export type CreateRatingResponse = {
  id: number
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type RatingRequest = {
  username: string
  rating: number
  comment?: string | null
}
export type PatchRatingRequest = {
  comment: string
  rating: number
}
export type Pronouns = 0 | 1 | 2 | 3
export type TeacherProfileResponse = {
  bio?: string | null
  experience?: string | null
  tags?: string[] | null
  video_link?: string | null
  teaching_style?: string | null
  price_per_hour?: number | null
  partner?: boolean | null
  is_reviewer?: boolean | null
}
export type LanguageExperience = 0 | 1 | 2 | 3 | 4
export type LanguageProficiency = {
  language: string
  proficiency: LanguageExperience
}
export type AddressInfoInDb = {
  country?: string | null
  city?: string | null
  state?: string | null
  address?: string | null
  zip_code?: string | null
}
export type UserStatus = 'STUDENT' | 'TEACHER'
export type PublicUserProfile = {
  id?: number | null
  username?: string | null
  email?: string | null
  bio?: string | null
  image?: string | null
  full_name?: string | null
  country?: string | null
  pronouns?: Pronouns | null
  date_of_birth?: string | null
  profile?: TeacherProfileResponse | null
  completed_onboarding?: boolean | null
  verified?: boolean | null
  accent?: string | null
  voice_gender?: string | null
  learning?: LanguageProficiency[] | null
  address?: AddressInfoInDb | null
  speaking?: LanguageProficiency[] | null
  status?: UserStatus
  display_name?: string | null
  is_company?: boolean | null
  cefr_level?: string | null
}
export type UserRating = {
  rater: PublicUserProfile
  updated_time: string
  created_time: string
  rating: number
  comment?: string | null
}
export const {
  useRatingRateUserMutation,
  useRatingDeleteRatingMutation,
  useRatingChangeRatingForUserMutation,
  useRatingGetRatingIdQuery,
  useLazyRatingGetRatingIdQuery,
  useRatingGetRatingsForUserQuery,
  useLazyRatingGetRatingsForUserQuery,
} = injectedRtkApi
